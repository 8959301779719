@page {
    margin: 0
}

body {
    margin: 0
}

.sheet.hidden {
    display: none !important;
}

.sheet {
    margin: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    page-break-after: always
}

body.A3 .sheet {
    /*width: 297mm;*/
    width: 11.6929in;
    /*height: 419mm;*/
    height: 16.4961in;
}

body.A3.landscape .sheet {
    /*width: 420mm;*/
    width: 16.5354in;
    /*height: 296mm;*/
    height: 11.6535in;
}

body.A4 .sheet {
    /*width: 210mm;*/
    width: 8.26772in;
    /*height: 296mm;*/
    height: 8.26772in;
}

body.A4.landscape .sheet {
    /*width: 297mm;*/
    width: 11.6929in;
    /*height: 209mm;*/
    height: 8.22835in;
}

body.A5 .sheet {
    /*width: 148mm;*/
    width: 5.82677in;
    /*height: 209mm;*/
    height: 8.22835in;

}

body.A5.landscape .sheet {
    /*width: 210mm;*/
    width: 8.26772in;
    /*height: 147mm;*/
    height: 5.7874in;
}

body.letter .sheet {
    /*width: 216mm;*/
    width: 8.5in;
    /*height: 279mm;*/
    height: 11in;
}

body.letter.landscape .sheet {
    /*width: 280mm;*/
    width: 11.0236in;
    /*height: 215mm;*/
    height: 8.46457in;
}

body.legal .sheet {
    /*width: 216mm;*/
    width: 8.5in;
    /*height: 356mm;*/
    height: 14in;
}

body.legal.landscape .sheet {
    /*width: 357mm;*/
    width: 14in;
    /*height: 215mm;*/
    height: 8.5in;
}

.sheet.padding-1-2 {
    /*padding: 10mm;*/
    padding: .5in;
}

.sheet.padding-1-4 {
    /*padding: 10mm;*/
    padding: .25in;
}

.sheet.padding-1-8 {
    /*padding: 10mm;*/
    padding: .125in;
}

.sheet.padding-1-16 {
    /*padding: 10mm;*/
    padding: .0625in;
}

.sheet.padding-10mm {
    /*padding: 10mm;*/
    padding: 0.393701in;
}

.sheet.padding-15mm {
    /*padding: 15mm;*/
    padding: 0.590551in;
}

.sheet.padding-20mm {
    /*padding: 20mm;*/
    padding: 0.787402in;
}

.sheet.padding-25mm {
    /*padding: 25mm;*/
    padding: 0.984252in;
}

@media screen {
    body {
        background: #e0e0e0
    }

    .sheet {
        background: #fff;
        /*box-shadow: 0 2mm 2mm rgba(0, 0, 0, .3);*/
        /*box-shadow: 0 0.0787402in 0.0787402in rgba(0, 0, 0, .3);*/
        /*margin: 2mm auto;*/
        margin: 0.19685in auto;
    }
}

@media print {
    body.A3.landscape {
        /*width: 420mm;*/
        width: 16.5354in;
    }

    body.A3, body.A4.landscape {
        /*width: 297mm;*/
        width: 11.6929in;
    }

    body.A4, body.A5.landscape {
        /*width: 210mm;*/
        width: 8.26772in;
    }

    body.A5 {
        /*width: 148mm;*/
        width: 5.82677in;
    }

    body.legal, body.letter {
        /*width: 216mm;*/
        width: 8.5in;
    }

    body.letter.landscape {
        /*width: 280mm;*/
        width: 11in;
    }

    body.legal.landscape {
        /*width: 357mm;*/
        width: 14in;
    }
}
